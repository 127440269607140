import React from 'react';
import '../style/Center.css';
import {IoIosMail, IoIosMailUnread, IoIosMailOpen} from "react-icons/io";
import * as iconsAi from "react-icons/ai";
import * as Icons from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsGi from "react-icons/gi";
import {MdErrorOutline} from "react-icons/md";
import {connect} from "react-redux";

function RowTable(props) {
    const {data, onClick} = props;
    let backColorConfirmed, backColorType, iconConfirmed, iconTransfers, iconType, iconVip, iconGoogle, iconVaucher,
        iconReceivedBy;

    function copyStringToClipboard(string) {
        function handler(event) {
            event.clipboardData.setData('text/plain', string);
            event.preventDefault();
            document.removeEventListener('copy', handler, true);
        }

        document.addEventListener('copy', handler, true);
        document.execCommand('copy');
        //*console.log('done')
    }

    function handleClick(e) {
        e.preventDefault();
        if (e.type === 'click') {
            //*console.log('Left click');
            onClick(data);
        } else if (e.type === 'contextmenu') {
            //*console.log('Right click',e.target.textContent);
            copyStringToClipboard(e.target.textContent);
        }
    }

    switch (data.confirmed) {
        case 'approved':
            backColorConfirmed = '#b0feb4';
            iconConfirmed = <IoIosMailOpen style={{color: 'black', fontSize: 30}}/>;
            break;
        case 'waiting':
            backColorConfirmed = '#ff9c9c';
            iconConfirmed = <IoIosMailUnread style={{color: 'black', fontSize: 30}}/>;
            break;
        case 'padding':
            backColorConfirmed = '#ff9047';
            iconConfirmed = <IoIosMail style={{color: 'black', fontSize: 30}}/>;
            break;
        default:
            backColorConfirmed = 'white';
            iconConfirmed = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
    }
    switch (data.google) {
        case 'Yes':
            iconGoogle = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'No':
            iconGoogle = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            iconGoogle = '';
    }
    switch (data.vip) {
        case 'Yes':
            iconVip = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'No':
            iconVip = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            iconVip = '';
    }
    switch (data.transfers) {
        case 'Yes':
            iconTransfers = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'No':
            iconTransfers = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            iconTransfers = '';
    }
    switch (data.vaucher) {
        case 'Yes':
            iconVaucher = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'No':
            iconVaucher = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            iconVaucher = '';
    }
    switch (data.received_by) {
        case 'System':
            iconReceivedBy = <Icons.MdComputer style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'Office':
            iconReceivedBy = <IconsFa.FaPenAlt style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            iconReceivedBy = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
    }
    switch (data.type) {
        case 'show':
            backColorType = '#d7dbff';
            iconType = <IconsGi.GiMicrophone style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'shuttle':
            backColorType = '#e3e3e3';
            iconType = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
            break;
        case '1':
            backColorType = '#e3e3e3';
            iconType = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
            break;
        case 'service':
            backColorType = '#f7cbb2';
            iconType = <IconsFa.FaHandsHelping style={{color: 'black', fontSize: 20}}/>;
            break;
        default:
            backColorType = 'white';
            iconType = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
    }
    if (props.initialState.excel_file === false)
        return (
            <tr style={{backgroundColor: backColorType}} onContextMenu={handleClick} onClick={handleClick} className={'rows-table'}>
                <td className="align-middle">{data.id}</td>
                <td className="align-middle">{data.date}</td>
                <td className="align-middle">{data.club.length > 20 ? data.club.slice(0, 14) + '\n' + data.club.slice(14) : !data.club.length ? '- - -' : data.club}</td>
                <td className="align-middle">{data.notes.length > 20 ? data.notes.slice(0, 14) + '\n' + data.notes.slice(14) : data.notes}</td>
                <td className="align-middle">{data.people}</td>
                <td className="align-middle">{data.name}</td>
                <td className="align-middle">{data.mobile}</td>
                <td className="align-middle">{data.email}</td>
                <td className="align-middle">{iconTransfers}</td>
                <td className="align-middle">{iconReceivedBy}</td>
                <td className="align-middle">{iconType}</td>
                <td className="align-middle" style={{backgroundColor: backColorConfirmed}}>{iconConfirmed}</td>
                <td className="align-middle">{data.who_put}</td>
                <td className="align-middle">{iconGoogle}</td>
                <td className="align-middle">{iconVip}</td>
                <td className="align-middle">{iconVaucher}</td>
                <td className="align-middle">{data.created_at}</td>
            </tr>
        );
    else
        return (
            <tr style={{backgroundColor: backColorType}} onClick={handleClick} onContextMenu={handleClick} className={'rows-table'}>
                <td className="align-middle">{data.id}</td>
                <td className="align-middle">{data.date}</td>
                <td className="align-middle">{data.club.length > 20 ? data.club.slice(0, 14) + '\n' + data.club.slice(14) : !data.club.length ? '- - -' : data.club}</td>
                <td className="align-middle">{data.notes.length > 20 ? data.notes.slice(0, 14) + '\n' + data.notes.slice(14) : data.notes}</td>
                <td className="align-middle">{data.people}</td>
                <td className="align-middle">{data.name}</td>
                <td className="align-middle">{data.mobile}</td>
                <td className="align-middle">{data.email}</td>
                <td className="align-middle">{data.transfers}</td>
                <td className="align-middle">{data.received_by}</td>
                <td className="align-middle">{data.type}</td>
                <td className="align-middle" style={{backgroundColor: backColorConfirmed}}>{data.confirmed}</td>
                <td className="align-middle">{data.who_put}</td>
                <td className="align-middle">{data.google}</td>
                <td className="align-middle">{data.vip}</td>
                <td className="align-middle">{data.vaucher}</td>
                <td className="align-middle">{data.created_at}</td>
            </tr>
        );
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispachToProps)(RowTable);
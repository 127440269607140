import React from 'react';
import TableRow from './TableRow';
import {PaginatedList} from 'react-paginated-list'
import styled from 'styled-components'
import {Table, TableBody, TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const ListContainer = styled.div`
    min-width: 100vw;
`
const ListContainerItem = styled.li`
    min-width: 100vw;
`
const RowsTable = ({
                       actionLastEditPageIndex,
                       lastEditPageIndex,
                       searchLabel,
                       onChangeNewDebounce,
                       actionLastEditRowId,
                       lastEditRowId,
                       isExcelView,
                       tableHeader,
                       resultSearch,
                       onSelectedSearch,
                       getRows,
                       onChange,
                       setIsEditRow,
                       getSerachRow,
                       checked,
                       fetchOrders,
                       fetchDeleteOrder,
                       fetchUpdateOrder,
                       closeOrderModal,
                       fetchSmsMessage,
                       fetchGmailMessage,
                       fetchWhatsappMessage,
                       initialState
                   }) => {
    // const pageSize=100
    // const totalItems=5000
    // const minLoadTime=250
    // const maxLoadTime=1250
    const items = resultSearch ? getSerachRow() : getRows()

    if (isExcelView) {
        return (
            <TableContainer component={Paper}>
                <Table id="orders-table" aria-label="collapsible table">
                    {tableHeader}
                    <TableBody>
                        {items.map((item) => {
                            return (
                                <TableRow
                                    searchLabel={searchLabel}
                                    onChangeNewDebounce={onChangeNewDebounce}
                                    actionLastEditRowId={actionLastEditRowId}
                                    isOpen={item.id === lastEditRowId}
                                    onChange={onChange}
                                    onSelectedSearch={onSelectedSearch}
                                    setIsEditRow={setIsEditRow}
                                    isEditRow={checked}
                                    fetchOrders={fetchOrders}
                                    fetchDeleteOrder={fetchDeleteOrder}
                                    fetchUpdateOrder={fetchUpdateOrder}
                                    close_modal={closeOrderModal}
                                    fetchSmsMessage={fetchSmsMessage}
                                    fetchGmailMessage={fetchGmailMessage}
                                    fetchWhatsappMessage={fetchWhatsappMessage}
                                    initialState={initialState}
                                    key={item.id}
                                    row={item}
                                    isExcel={initialState.excel_file}/>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
    return (
        <PaginatedList
            list={items}
            itemsPerPage={50}
            onPageChange={(p, currentPage) => {
                actionLastEditPageIndex(currentPage)
            }}
            currentPage={lastEditPageIndex}
            renderList={(list) => {
                return (
                    <TableContainer component={Paper} style={{ overflowX: 'initial' }}>
                        <Table id="orders-table" aria-label="collapsible table">
                            {tableHeader}
                            <TableBody>
                                {list.map(item => {
                                    return (
                                        <TableRow
                                            searchLabel={searchLabel}
                                            onChangeNewDebounce={onChangeNewDebounce}
                                            actionLastEditRowId={actionLastEditRowId}
                                            isOpen={item.id === lastEditRowId}
                                            onChange={onChange}
                                            onSelectedSearch={onSelectedSearch}
                                            setIsEditRow={setIsEditRow}
                                            isEditRow={checked}
                                            fetchOrders={fetchOrders}
                                            fetchDeleteOrder={fetchDeleteOrder}
                                            fetchUpdateOrder={fetchUpdateOrder}
                                            close_modal={closeOrderModal}
                                            fetchSmsMessage={fetchSmsMessage}
                                            fetchGmailMessage={fetchGmailMessage}
                                            fetchWhatsappMessage={fetchWhatsappMessage}
                                            initialState={initialState}
                                            key={item.id}
                                            row={item}
                                            isExcel={initialState.excel_file}/>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )
            }}
            controlItemClass="pagination-item"
            controlClass="pagination"
            activeControlClass="active"
            breakClass="pagination-break"
            leftMargin={6}
            rightMargin={6}
        />);
}

export default React.memo(RowsTable)
import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import  * as IconsFa  from "react-icons/fa";
import moment from 'moment';
import {
  DatePicker, 
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DatePick(props) {
  const [selectedDate, handleDateChange] = useState(props.value && moment(props.value.replaceAll('/','-').replaceAll('.','-').replaceAll(' ',''),'YYYY-MM-DD').isValid() ? new Date (reverse(props.value.replaceAll('.','-').replaceAll('/','-').replaceAll(' ',''))) : null);
  function join(t, a, s) {
    function format(m) {
       let f = new Intl.DateTimeFormat('en', m);
       return f.format(t);
    }
    return a.map(format).join(s);
 }
    const onChangeDate = (date) =>{
        if(moment(date).isValid()){
            handleDateChange(date) 
            let a = [{day: 'numeric'}, {month: 'numeric'}, {year: 'numeric'}];
            let s = join(date, a, '/');
            props.onChange(s,'Date') 
        }
    } 
    function reverse(s){
      let date = s.split("-")
      let d = date[0]
      let m = date[1]
      let y = date[2]
      return `${y + '-' + m + '-' + d}`
  }  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}> 
    <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center',margin:0, paddingRight: 10, paddingLeft: 10}}>
    <IconsFa.FaRegCalendarAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>
      <KeyboardDatePicker   
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date"
          value={selectedDate}
          onChange={onChangeDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
    </div>
    </MuiPickersUtilsProvider>
  );
}
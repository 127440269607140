import React, {useCallback, useState} from 'react';
import {connect} from "react-redux";
import RowTable from './RowTable.js';
import primaryValues from '../constants/Values.js';
import '../App.css';
import '../style/Center.css';
import '../../node_modules/bootstrap-css-only/css/bootstrap.css';
import moment from 'moment';
import {Scrollbars} from 'react-custom-scrollbars';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip';
import * as iconsAi from "react-icons/ai";
import * as Icons from "react-icons/md";
import * as IconsFa from "react-icons/fa";
import * as IconsFi from "react-icons/fi";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import NewOrder from './NewOrder';
import NewClub from './NewClub';
import NewUser from './NewUser';
import VaucherImage from './VaucherImage';
import {makeStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {IoIosMail, IoIosMailUnread, IoIosMailOpen} from "react-icons/io";
import * as IconsGi from "react-icons/gi";
import {MdErrorOutline, MdCancel} from "react-icons/md";
import {BiSortDown, BiSortUp, BiSort} from "react-icons/bi";
import RowsTable from './RowsTable';
import {modalServices} from '../services';
import Login from "../pages/Login";

const defaultFilterOptions = createFilterOptions({
    limit: 50
});

const debounce = require('lodash.debounce');

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const NewSearchBar = (props) => {
    const [value, setValue] = useState('')
    const onChangeValueDebounce = (val) => {
        props.onChangeNew(val)
    }
    const onChangeValueDebounced = debounce(onChangeValueDebounce, 500)

    const onChange = useCallback((val) => {
        let searchBy = typeof val === 'string' ? val : val.target.value;
        onChangeValueDebounced(searchBy)
        setValue(searchBy)
        if (searchBy === '')
            props.onChangeNew('')
    }, [setValue, onChangeValueDebounced, props])

    return (
        <div style={{
            overflow: 'hidden',
            maxWidth: '95vw',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }}>
            <div id="search-bar" style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row'}}>
                <div className={'menu-bar-autocomplete'} style={{width: 274}}>
                    <TextField InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}} value={value}
                               onChange={onChange} label={'Free'} fullWidth />
                </div>
                <button className="menu-btn" data-place='bottom' data-tip="Free Search" disabled={props.modalActive}
                        onClick={() => {
                            props.onChangeNewDebounce(props.searchLabel)
                        }}
                        style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaSearch style={{fontSize: 18}}/>
                </button>
            </div>
        </div>)
}


const SearchBar = (props) => {
    const [searchMode, setSearchMode] = useState(false)
    const [searchType, setSearchType] = useState({name: ''});
    return <div style={{flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 15}}>
        <button style={{ marginTop: 13, marginRight: 10 }} className="menu-btn" data-place='bottom' data-tip="Search Mode"
                disabled={props.modalActive} onClick={() => {
            setSearchMode(r => !r)
        }}><IconsFa.FaExchangeAlt style={{fontSize: 18}} /></button>
        {searchMode ? (<div style={{
            overflow: 'hidden',
            maxWidth: '95vw',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
        }}>
            <div id="search-bar" style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'row'}}>
                <div className={'menu-bar-autocomplete'} style={{width: 110}}>
                    <Autocomplete
                        data-tip="Select Search Mode"
                        options={[{name: 'ID'}, {name: 'Name'}, {name: 'Mobile'}, {name: 'Email'}]}
                        getOptionLabel={option => option.name}
                        color={'red'}
                        value={searchType}
                        onChange={(e, value) => {
                            setSearchType(value ?? {name: ''});
                            props.onChange(e);
                        }}
                        renderInput={params => (
                            <TextField {...params} InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}}
                                       label={'Search'} fullWidth />
                        )} />
                </div>
                <div className={'menu-bar-autocomplete'} style={{width: 210}}>
                    <Autocomplete
                        filterOptions={defaultFilterOptions}
                        data-tip={props.searchLabel === '' ? props.searchTlitip : "Enter Order Info"}
                        options={props.searchLabel === '' ? [{name: ''}] : props.search}
                        getOptionLabel={option => option.name + ''}
                        onChange={(e) => props.onSelectedSearch(e)}
                        renderInput={params => (
                            <TextField {...params} InputLabelProps={{style: {color: '#ebebeb', fontWeight: 'bold'}}}
                                       label={searchType.name} fullWidth />
                        )} />
                </div>
            </div>
        </div>) : <NewSearchBar {...props} />}
    </div>
}

class Center extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_permission: this.props.initialState.user_permission,
            id: '',
            menuWidth: 0,
            isLoading: false,
            excelBtnOpacity: 0,
            // searchLabel: this.props.searchLabel,
            modalActive: false,
            orderInfo: {},
            editRows: {},
            currentCount: 3,
            sort: 'default',
            checked: false,
            menuStatus: null,
            lastEditRowId: null,
            // searchTlitip: this.props.searchTlitip,
            // search: this.props.search,
            // resultSearch: this.props.resultSearch,
            width: 0,
            height: 0
        }
        this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
        this.props.fetchClubs({type: primaryValues.$GET_CLUBS});
        this.props.fetchUsers({type: primaryValues.$GET_USERS});
        this.props.fetchImagesNames({type: primaryValues.$GET_IMAGES_LIST});
        this.checkSortTable = this.checkSortTable.bind(this);
        this.closeOrderModal = this.closeOrderModal.bind(this);
        this.menuWidth = this.menuWidth.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    createData = (id, date, club, notes, people, name, mobile, email, price, transfers, received, type, confirmed, pot, google, vip, vaucher, created, lang) => {
        let backColorConfirmed, confirmedValue, googleValue, transfersValue, vipValue, vaucherValue, receivedValue,
            typeValue;

        if (this.props.initialState.excel_file === true) {
            switch (confirmed) {
                case 'approved':
                    backColorConfirmed = '#b0feb4';
                    break;
                case 'aprovmed':
                    backColorConfirmed = '#b0feb4';
                    break;
                case 'waiting':
                    backColorConfirmed = '#ff9c9c';
                    break;
                case 'padding':
                    backColorConfirmed = '#ff9047';
                    break;
                default:
                    backColorConfirmed = 'white';
            }
        } else
            switch (confirmed) {
                case 'approved':
                    backColorConfirmed = '#b0feb4';
                    confirmedValue = 'Aprovmed';
                    confirmed = <IoIosMailOpen style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'aprovmed':
                    backColorConfirmed = '#b0feb4';
                    confirmedValue = 'Aprovmed';
                    confirmed = <IoIosMailOpen style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'waiting':
                    backColorConfirmed = '#ff9c9c';
                    confirmedValue = 'Waiting';
                    confirmed = <IoIosMailUnread style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'padding':
                    backColorConfirmed = '#ff9047';
                    confirmedValue = 'Sent';
                    confirmed = <IoIosMail style={{color: 'black', fontSize: 30}}/>;
                    break;
                case 'cancel':
                    backColorConfirmed = '#fcffca';
                    confirmedValue = 'Cancel';
                    confirmed = <MdCancel style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    backColorConfirmed = 'white';
                    confirmedValue = '';
                    confirmed = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
            }

        if (this.props.initialState.excel_file !== true)
            switch (google) {
                case 'Yes':
                    googleValue = 'Yes';
                    google = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    googleValue = 'No';
                    google = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    googleValue = '';
                    google = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (vip) {
                case 'Yes':
                    vipValue = 'Yes';
                    vip = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    vipValue = 'No';
                    vip = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    vipValue = '';
                    vip = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (transfers) {
                case 'Yes':
                    transfersValue = 'Yes';
                    transfers = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    transfersValue = 'Yes';
                    transfers = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    transfersValue = '';
                    transfers = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (vaucher) {
                case 'Yes':
                    vaucherValue = 'Yes';
                    vaucher = <iconsAi.AiTwotoneLike style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'No':
                    vaucherValue = 'No';
                    vaucher = <iconsAi.AiTwotoneDislike style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    vaucherValue = '';
                    vaucher = '';
            }

        if (this.props.initialState.excel_file !== true)
            switch (received) {
                case 'System':
                    receivedValue = 'System';
                    received = <Icons.MdComputer style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'Office':
                    receivedValue = 'Office';
                    received = <IconsFa.FaPenAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    receivedValue = '';
                    received = <MdErrorOutline style={{color: 'black', fontSize: 20}}/>;
            }

        let backColorType;
        if (this.props.initialState.excel_file === true) {
            switch (type) {
                case 'show':
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    break;
                case 'shuttle':
                    typeValue = 'Shuttle';
                    backColorType = '#e3e3e3';
                    break;
                case '1':
                    typeValue = 'New Type';
                    backColorType = '#e3e3e3';
                    break;
                case 'service':
                    typeValue = 'Service';
                    backColorType = '#f7cbb2';
                    break;
                default:
                    typeValue = '';
                    backColorType = 'white';
            }
        } else
            switch (type) {
                case 'show':
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    type = <IconsGi.GiMicrophone style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'shuttle':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'transfer':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                case 'service':
                    typeValue = 'NewType';
                    backColorType = '#f7cbb2';
                    type = <IconsFa.FaBusAlt style={{color: 'black', fontSize: 20}}/>;
                    break;
                default:
                    typeValue = 'Performances';
                    backColorType = '#d7dbff';
                    type = <IconsGi.GiMicrophone style={{color: 'black', fontSize: 20}}/>;
            }

        return {
            id,
            date,
            club,
            notes,
            people,
            name,
            mobile,
            email,
            price,
            transfers,
            received,
            type,
            confirmed,
            pot,
            google,
            vip,
            vaucher,
            created,
            backColorType,
            backColorConfirmed,
            confirmedValue,
            googleValue,
            transfersValue,
            vipValue,
            vaucherValue,
            receivedValue,
            typeValue,
            history: [
                {date: '2020-01-05', customerId: '11091700', amount: 3},
                {date: '2020-01-02', customerId: 'Anonymous', amount: 1},
            ],
            lang
        };
    }
    getSerachRow = () => {
        let rows = this.props.resultSearch;
        return this.createRows(rows);
    }
    getRows = () => {
        let rows = this.checkSortTable();
        return this.createRows(rows);
    }
    createRows(rows) {
        let rowsToShow = [];
        for (let i = 0; i < rows.length; i++) {
            rowsToShow.push(this.createData(rows[i].id, rows[i].date === 'undefined' || rows[i].date.toString().length < 8 ? '' : rows[i].date, rows[i].club, rows[i].notes, rows[i].people, rows[i].name, rows[i].mobile, rows[i].email, rows[i].price, rows[i].transfers, rows[i].received_by, rows[i].type, rows[i].confirmed.toLowerCase(), rows[i].who_put, rows[i].google, rows[i].vip, rows[i].vaucher, rows[i].created_at, rows[i].lang))
        }
        return rowsToShow;
    }

    componentDidMount() {
        modalServices.newOrder = this.newOrder;
        modalServices.closeNewOrderModalModal = this.closeModal;
        modalServices.openNewOrderModal = this.openNewOrderModal;
    }

    fetchDetails = (order) => {
        // clearInterval(this.refres);
        this.setState({modalActive: true});
        this.setState({orderInfo: order});
    }
    closeModal = () => {
        modalServices.setCompyOrderData(null)
        modalServices.isNew = false
        this.setState({checked: !this.state.checked});
        this.setState({menuWidth: 0});
    }
    openNewOrderModal = () => {
        if (!this.state.checked) {
            this.setState({menuWidth: 300});
            this.setState({checked: !this.state.checked});
        } else if (this.state.menuStatus === 'add-new-order') {
            this.setState({menuWidth: 0});
            this.setState({checked: !this.state.checked});
        }
        this.setState({menuStatus: 'add-new-order'});
    }
    closeOrderModal = () => {
        // this.setState({modalActive: false});
        this.props.initialState.send_success = false;
    }

    menuWidth = () => {
        this.menu = setInterval(() => {
            if (this.state.menuWidth < 300) {
                this.setState({menuWidth: this.state.menuWidth + 1});
            } else
                clearInterval(this.menu);
        }, 1);
    }

    checkDate(string) {
        return ((typeof string === 'string' && /\d{4}-\d{2}-\d{2}/.test(string)) || (typeof string === 'string' && /\d{4}-\d{2}-\d{2} \d{2}-\d{2}-\d{2}/.test(string)));
    }

    checkSortTable = () => {
        let res = this.props.initialState.orders;
        switch (this.state.sort) {
            case 'default':
                // res = res.reverse();
                return res;
            case 'date-up':
                res.sort(function compare(a, b) {
                    if ((a.date + '').toString().length > 10)
                        a.date = a.date.toString().substring(0, 10);
                    if ((b.date + '').toString().length > 10)
                        b.date = b.date.toString().substring(0, 10);
                    let datea = a.date.indexOf('/') !== -1 ? a.date : moment(a.date + '').isValid() ? moment(a.date + '').format('DD/MM/YYYY') : undefined;
                    let dateb = b.date.indexOf('/') !== -1 ? b.date : moment(b.date + '').isValid() ? moment(b.date + '').format('DD/MM/YYYY') : undefined;
                    if (!a.date || a.date === '01/01/2030' || a.date === '' || a.date.indexOf('undefined') !== -1 || datea === undefined || a.date === undefined || (a.date && a.date.toString().length < 8))
                        datea = '01/01/1930';
                    if (!b.date || b.date === '01/01/2030' || b.date === '' || b.date.indexOf('undefined') !== -1 || dateb === undefined || b.date === undefined || (b.date && b.date.toString().length < 8))
                        dateb = '01/01/1930';
                    a.date = datea;
                    b.date = dateb;
                });
                res.sort(function compare(a, b) {
                    let datea = a.date
                    let dateb = b.date
                    let da = datea.split('/');
                    let db = dateb.split('/');
                    if (da[0].length === 1)
                        da[0] = '0' + da[0];
                    if (da[1].length === 1)
                        da[1] = '0' + da[1];
                    if (db[0].length === 1)
                        db[0] = '0' + db[0];
                    if (db[1].length === 1)
                        db[1] = '0' + db[1];
                    datea = da.reverse().join('');
                    dateb = db.reverse().join('');
                    return parseInt(dateb) - parseInt(datea)
                });
                return res;
            case 'date-down':
                res.sort(function compare(a, b) {
                    if ((a.date + '').toString().length > 10)
                        a.date = a.date.toString().substring(0, 10);
                    if ((b.date + '').toString().length > 10)
                        b.date = b.date.toString().substring(0, 10);
                    let datea = a.date.indexOf('/') !== -1 ? a.date : moment(a.date + '').isValid() ? moment(a.date + '').format('DD/MM/YYYY') : undefined;
                    let dateb = b.date.indexOf('/') !== -1 ? b.date : moment(b.date + '').isValid() ? moment(b.date + '').format('DD/MM/YYYY') : undefined;
                    if (!a.date || a.date === '' || a.date === '01/01/1930' || a.date.indexOf('undefined') !== -1 || datea === undefined || a.date === undefined || (a.date && a.date.toString().length < 8))
                        datea = '01/01/2030';
                    if (!b.date || b.date === '' || b.date === '01/01/1930' || b.date.indexOf('undefined') !== -1 || dateb === undefined || b.date === undefined || (b.date && b.date.toString().length < 8))
                        dateb = '01/01/2030';
                    a.date = datea;
                    b.date = dateb;
                });
                res.sort(function compare(a, b) {
                    let datea = a.date
                    let dateb = b.date
                    let da = datea.split('/');
                    let db = dateb.split('/');
                    if (da[0].length === 1)
                        da[0] = '0' + da[0];
                    if (da[1].length === 1)
                        da[1] = '0' + da[1];
                    if (db[0].length === 1)
                        db[0] = '0' + db[0];
                    if (db[1].length === 1)
                        db[1] = '0' + db[1];
                    datea = da.reverse().join('');
                    dateb = db.reverse().join('');
                    return parseInt(datea) - parseInt(dateb)
                });
                return res;
            case 'club':
                res.sort(function compare(a, b) {
                    let cluba = a.club;
                    let clubb = b.club;
                    return cluba.toUpperCase() > clubb.toUpperCase() ? -1 : cluba.toUpperCase() < clubb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'type':
                res.sort(function compare(a, b) {
                    let vala = a.type;
                    let valb = b.type;
                    if (valb.toString().length < 2)
                        valb = 'show';
                    if (vala.toString().length < 2)
                        vala = 'show';
                    if (valb.toString() === 'service')
                        valb = 'transfer';
                    if (vala.toString() === 'service')
                        vala = 'transfer';
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'confirmed':
                res.sort(function compare(a, b) {
                    let confirmeda = a.confirmed;
                    let confirmedb = b.confirmed;
                    return confirmeda.toUpperCase() > confirmedb.toUpperCase() ? -1 : confirmeda.toUpperCase() < confirmedb.toUpperCase() ? 1 : 0;
                });
                return res
            case 'people':
                res.sort(function compare(a, b) {
                    return parseInt(a.people) - parseInt(b.people);
                });
                return res.reverse();
            case 'id':
                res.sort(function compare(a, b) {
                    return parseInt(a.id) - parseInt(b.id);
                });
                return res.reverse();
            case 'received_by':
                res.sort(function compare(a, b) {
                    let valb = a.received_by;
                    let vala = b.received_by;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'transfers':
                res.sort(function compare(a, b) {
                    let valb = a.transfers;
                    let vala = b.transfers;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'name':
                res.sort(function compare(a, b) {
                    let valb = a.name;
                    let vala = b.name;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res;
            case 'email':
                res.sort(function compare(a, b) {
                    let valb = a.email;
                    let vala = b.email;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res;
            case 'who_put':
                res.sort(function compare(a, b) {
                    let valb = a.who_put;
                    let vala = b.who_put;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'google':
                res.sort(function compare(a, b) {
                    let valb = a.google;
                    let vala = b.google;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'vip':
                res.sort(function compare(a, b) {
                    let valb = a.vip;
                    let vala = b.vip;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            case 'vaucher':
                res.sort(function compare(a, b) {
                    let valb = a.vaucher;
                    let vala = b.vaucher;
                    return vala.toUpperCase() > valb.toUpperCase() ? -1 : vala.toUpperCase() < valb.toUpperCase() ? 1 : 0;
                });
                return res.reverse();
            default:
                res = res.reverse();
                return res;
        }
    }
    onChange = (val) => {
        let orders = this.props.initialState.orders;
        let searchBy = val.target.textContent;
        let searchArray = [];
        orders.forEach(order => {
            if (searchBy === 'ID' && !searchArray.includes({name: order.id}))
                searchArray.push({name: order.id + ''});
            if (searchBy === 'Name' && !searchArray.includes({name: order.name})) {
                searchArray.push({name: order.name});
            }
            if (searchBy === 'Email' && !searchArray.includes({name: order.email}))
                searchArray.push({name: order.email});
            if (searchBy === 'Mobile' && !searchArray.includes({name: order.mobile}))
                searchArray.push({name: order.mobile});
        });
        this.props.setSearchLabel(val)
        this.props.setSearch(searchArray)
    }
    newClub = (data) => {
        let params = data;
        this.props.fetchNewClub({type: primaryValues.$GET_NEW_CLUB, params});
        this.closeModal()
    }
    newOrder = (data) => {
        let params = data;
        this.props.fetchNewOrder({type: primaryValues.$GET_NEW_ORDER, params});
        this.closeModal()
    }
    newUser = (data) => {
        let params = data;
        this.props.fetchNewUser({type: primaryValues.$GET_NEW_USER, params});
        this.closeModal()
    }
    vaucherImage = (data) => {
        this.props.initialState.images_list.forEach(element => {
            if (element.name === data.url_image?.toLowerCase()) {
                data.url_image = element.url;
            }
        });
        let params = data;
        this.props.fetchNewVaucherImage({type: primaryValues.$GET_NEW_IMAGE, params});
        this.closeModal()
    }
    actionLastEditRowId = (id) => {
        this.props.fetchLastEditRowId({type: primaryValues.$GET_LAST_ROW_EDIT_ID, id});
    }
    actionLastEditPageIndex = (pageIndex) => {
        this.props.fetchEditPageIndex({type: primaryValues.$GET_EDIT_PAGE_INDEX, pageIndex});
    }

    getTableHeader = () => {
        return (
            <TableHead style={{ position: 'sticky', top: 0, background: '#fff' }}>
                <TableRow>
                    <TableCell/>
                    <TableCell data-tip="Filter By Number"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'id' ?
                                       this.setState({sort: 'id'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Order #</TableCell>
                    <TableCell data-tip="Filter By Date"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'date-up' && this.state.sort !== 'date-down' ?
                                       this.setState({sort: 'date-up'}) :
                                       this.state.sort !== 'date-down' ?
                                           this.setState({sort: 'date-down'}) :
                                           this.setState({sort: 'default'}, () => this.props.fetchOrders({type: primaryValues.$GET_ORDERS}));
                               }} align="center">{
                        this.state.sort === 'date-up' ?
                            <BiSortUp/> :
                            this.state.sort === 'date-down' ?
                                <BiSortDown/> :
                                this.state.sort === 'date' ?
                                    <BiSort/> : null
                    } Date</TableCell>
                    <TableCell data-tip="Filter By Contact"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'club' ?
                                       this.setState({sort: 'club'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Order Contact</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Notes</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}} align="center">Time /
                        People</TableCell>
                    <TableCell data-tip="Filter By Name"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'name' ?
                                       this.setState({sort: 'name'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Name</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Mobile</TableCell>
                    <TableCell data-tip="Filter By Email"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'email' ?
                                       this.setState({sort: 'email'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Email</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}}
                               align="center">Price</TableCell>
                    <TableCell data-tip="Filter By Transfers"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'transfers' ?
                                       this.setState({sort: 'transfers'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Transfers</TableCell>
                    <TableCell data-tip="Filter By Received "
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'received_by' ?
                                       this.setState({sort: 'received_by'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Received</TableCell>
                    <TableCell data-tip="Filter By Type"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'type' ?
                                       this.setState({sort: 'type'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Type</TableCell>
                    <TableCell data-tip="Filter By Confirmed"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'confirmed' ?
                                       this.setState({sort: 'confirmed'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Confirmed</TableCell>
                    <TableCell data-tip="Filter By Created"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'who_put' ?
                                       this.setState({sort: 'who_put'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Created By</TableCell>
                    <TableCell data-tip="Filter By Google"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'google' ?
                                       this.setState({sort: 'google'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Google</TableCell>
                    <TableCell data-tip="Filter By Vip"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'vip' ?
                                       this.setState({sort: 'vip'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Vip</TableCell>
                    <TableCell data-tip="Filter By Vaucher"
                               style={{cursor: 'pointer', fontWeight: 'bold', margin: 5}}
                               onClick={() => {
                                   this.state.sort !== 'vaucher' ?
                                       this.setState({sort: 'vaucher'}) :
                                       this.setState({sort: 'default'});
                                   this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                               }} align="center">Vaucher</TableCell>
                    <TableCell style={{fontWeight: 'bold', margin: 5}} align="center">Created
                        Date</TableCell>
                    {/* <TableCell style={{ fontWeight:'bold',margin:5 }} align="center">Created Date</TableCell>  */}
                </TableRow>
            </TableHead>
        )
    }

    render() {
        if (this.props.initialState.orders_success && !this.state.isLoading)
            return (
                <div className='center-app' style={{justifyContent: 'flex-start'}}>
                    <ReactTooltip/>
                    <div style={{
                        width: '100%',
                        fontSize: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'visible'
                    }}>
                        <img onClick={() => window.location.reload()} id="main-img" src={process.env.REACT_APP_HOST_URL + '/public/images/logoE.png'} style={{
                            width: 150,
                            position: 'absolute',
                            left: 20,
                            top: -10,
                            cursor: 'pointer',
                            zIndex: 10,
                        }} alt='logo' />
                        <div style={{
                            width: '100%',
                            fontSize: 12,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                                {this.state.excelBtnOpacity === 1 &&
                                    <div className="menu-btn" data-place='bottom' data-tip="Download Excel File">
                                        <ReactHTMLTableToExcel
                                            className='export-excel-btn'
                                            table='orders-table'
                                            filename='Orders Nights Greek'
                                            sheet='sheet 1'
                                            buttonText="Export to Excel"
                                        />
                                    </div>
                                }
                                <button className="menu-btn" data-place='bottom' data-tip="Add New Order"
                                        disabled={this.state.modalActive} onClick={() => {
                                    modalServices.setCompyOrderData(null)
                                    modalServices.isNew = true
                                    if (!this.state.checked) {
                                        this.setState({menuWidth: 300});
                                        this.setState({checked: !this.state.checked});
                                    } else if (this.state.menuStatus === 'add-new-order') {
                                        this.setState({menuWidth: 0});
                                        this.setState({checked: !this.state.checked});
                                    }
                                    this.setState({menuStatus: 'add-new-order'});
                                }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFi.FiFilePlus
                                    style={{fontSize: 18}}/></button>
                                <div style={{
                                    opacity: this.state.user_permission == 1 ? 1 : 0.3,
                                    pointerEvents: this.state.user_permission == 1 ? 'auto' : 'none'
                                }}>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add New Contact"
                                            disabled={this.state.modalActive} onClick={() => {
                                        if (!this.state.checked) {
                                            this.setState({menuWidth: 300});
                                            this.setState({checked: !this.state.checked});
                                        } else if (this.state.menuStatus === 'add-new-club') {
                                            this.setState({menuWidth: 0});
                                            this.setState({checked: !this.state.checked});
                                        }
                                        this.setState({menuStatus: 'add-new-club'});
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaRegBuilding
                                        style={{fontSize: 18}}/></button>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add New User"
                                            disabled={this.state.modalActive} onClick={() => {
                                        if (!this.state.checked) {
                                            this.setState({menuWidth: 300});
                                            this.setState({checked: !this.state.checked});
                                        } else if (this.state.menuStatus === 'add-new-user') {
                                            this.setState({menuWidth: 0});
                                            this.setState({checked: !this.state.checked});
                                        }
                                        this.setState({menuStatus: 'add-new-user'});
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaUserPlus
                                        style={{fontSize: 18}}/></button>
                                    <button className="menu-btn" data-place='bottom' data-tip="Add Vaucher Image"
                                            disabled={this.state.modalActive} onClick={() => {
                                        if (!this.state.checked) {
                                            this.setState({menuWidth: 300});
                                            this.setState({checked: !this.state.checked});
                                        } else if (this.state.menuStatus === 'add-vaucher-image') {
                                            this.setState({menuWidth: 0});
                                            this.setState({checked: !this.state.checked});
                                        }
                                        this.setState({menuStatus: 'add-vaucher-image'});
                                    }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaTicketAlt
                                        style={{fontSize: 18}}/></button>
                                </div>
                                <button className="menu-btn" data-place='bottom' data-tip="Excel File View"
                                        disabled={this.state.modalActive} onClick={() => {
                                    if (this.props.initialState.excel_file === false) {
                                        this.setState({isLoading: true}, async () => {
                                            setTimeout(() => {
                                                this.props.fetchExcelFile({type: primaryValues.$GET_EXCEL_FILE});
                                                this.setState({excelBtnOpacity: 1}, () => this.setState({isLoading: false}));
                                            }, 500);
                                        });
                                    } else {
                                        this.props.fetchExcelFile({type: primaryValues.$SET_TABLE_VIEW});
                                        this.setState({excelBtnOpacity: 0});
                                    }
                                }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><IconsFa.FaFileExcel
                                    style={{fontSize: 18}}/></button>
                                <button className="menu-btn" data-place='bottom' data-tip="Refresh"
                                        disabled={this.state.modalActive} onClick={() => {
                                    const searchLabel = this.props.searchLabel
                                    const sort = this.state.sort
                                    this.props.onSelectedSearch({target: {textContent: ''}})
                                    this.props.setSearchLabel('')
                                    this.props.setSearch([{name: ''}])
                                    this.setState({sort: 'default'})
                                    this.props.fetchOrders({type: primaryValues.$GET_ORDERS});
                                    setTimeout(() => {
                                        this.setState({sort: sort})
                                        if (searchLabel)
                                            this.props.onChangeNewDebounce(searchLabel)
                                    }, 250);
                                }} style={{margin: 4, paddingRight: 8, paddingLeft: 8}}><Icons.MdAutorenew
                                    style={{fontSize: 18}}/></button>
                                <div data-place='bottom' data-tip="Filter Orders Mode">
                                    <button id="filter-btn" disabled={true} style={{
                                        margin: 4,
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        border: 0,
                                        color: '#9a1d1d',
                                        fontWeight: 'bold',
                                        textTransform: 'uppercase'
                                    }}>{this.state.sort}</button>
                                </div>
                            </div>
                        </div>
                        <SearchBar {...{
                            actionLastEditPageIndex: this.actionLastEditPageIndex,
                            modalActive: this.state.modalActive,
                            onChangeNewDebounce: this.props.onChangeNewDebounce,
                            onChangeNew: this.props.onChangeNew,
                            new: true,
                            onChange: this.onChange,
                            searchLabel: this.props.searchLabel,
                            searchTlitip: this.props.searchTlitip,
                            onSelectedSearch: this.props.onSelectedSearch,
                            search: this.props.search
                        }}/>
                        <div style={{height: this.state.menuWidth, zIndex: 100}}>
                            <Collapse in={this.state.checked}>
                                <div style={{
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'absolute',
                                    top: 100,
                                    left: 0,
                                    zIndex: -1
                                }} onClick={() => this.closeModal()}></div>
                                <Paper elevation={4}>
                                    <div className={'menu-bar'} style={{backgroundColor: '#ebebeb', padding: 10}}>
                                        {/* <Scrollbars style={{width:'100%',backgroundColor:'#ebebeb',overflowX:'hidden'}}> */}
                                        {this.state.menuStatus === 'add-new-order' ?
                                            <NewOrder copyOrderData={modalServices.copyOrderData}
                                                      closeModal={this.closeModal} onClick={this.newOrder}/> : null}
                                        {this.state.menuStatus === 'add-new-club' ?
                                            <NewClub closeModal={this.closeModal} onClick={this.newClub}/> : null}
                                        {this.state.menuStatus === 'add-new-user' ?
                                            <NewUser closeModal={this.closeModal} onClick={this.newUser}/> : null}
                                        {this.state.menuStatus === 'add-vaucher-image' ?
                                            <VaucherImage closeModal={this.closeModal}
                                                          onClick={this.vaucherImage}/> : null}
                                        {/* </Scrollbars> */}
                                    </div>
                                </Paper>
                            </Collapse>
                        </div>

                        <div id={'dash-footer'} style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '25px 20px',
                        }}>
                            <p style={{fontSize: 12, maxWidth: 200, margin: 0}}>{`version - ${process.env.REACT_APP_VERSION}`}</p>
                            <Login logout={true}/>
                        </div>
                    </div>
                    <Scrollbars id="scroll-bar">
                        <RowsTable {...{
                            actionLastEditPageIndex: this.actionLastEditPageIndex,
                            lastEditPageIndex: this.props.initialState.lastEditPageIndex,
                            searchLabel: this.props.searchLabel,
                            onChangeNewDebounce: this.props.onChangeNewDebounce,
                            lastEditRowId: this.props.initialState.lastEditRowId,
                            actionLastEditRowId: this.actionLastEditRowId,
                            isExcelView: this.state.excelBtnOpacity === 1,
                            tableHeader: this.getTableHeader(),
                            getRows: this.getRows,
                            onChange: this.onChange,
                            setIsEditRow: this.setIsEditRow,
                            getSerachRow: this.getSerachRow,
                            checked: this.state.checked,
                            closeOrderModal: this.closeOrderModal,
                            resultSearch: this.props.resultSearch,
                            onSelectedSearch: this.props.onSelectedSearch,
                            fetchOrders: this.props.fetchOrders,
                            fetchDeleteOrder: this.props.fetchDeleteOrder,
                            fetchUpdateOrder: this.props.fetchUpdateOrder,
                            fetchSmsMessage: this.props.fetchSmsMessage,
                            fetchGmailMessage: this.props.fetchGmailMessage,
                            fetchWhatsappMessage: this.props.fetchWhatsappMessage,
                            initialState: this.props.initialState
                        }}/>
                    </Scrollbars>
                </div>
            )
        else
            return (
                <Scrollbars style={{width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0)'}}>
                    <div className='App' style={{backgroundColor: '#181818'}}>
                        <p style={{
                            fontSize: '14px',
                            color: 'white'
                        }}>{this.state.isLoading ? 'Get Ready To Excel Data Table...' : 'Trying to connect the System...'}</p>
                    </div>
                </Scrollbars>
            );
    }
}

const mapStateToProps = state => {
    return {
        initialState: state.initialState,
    };
};
const mapDispachToProps = dispatch => {
    return {
        fetchOrders: (info) => dispatch(info),
        fetchClubs: (info) => dispatch(info),
        fetchUsers: (info) => dispatch(info),
        fetchExcelFile: (info) => dispatch(info),
        fetchImagesNames: (info) => dispatch(info),
        fetchNewUser: (info) => dispatch(info),
        fetchNewClub: (info) => dispatch(info),
        fetchNewOrder: (info) => dispatch(info),
        fetchNewVaucherImage: (info) => dispatch(info),
        fetchLastEditRowId: (info) => dispatch(info),
        trigerModalMsg: (info) => dispatch(info),
        fetchWhatsappMessage: (info) => dispatch(info),
        fetchGmailMessage: (info) => dispatch(info),
        fetchSmsMessage: (info) => dispatch(info),
        fetchUpdateOrder: (info) => dispatch(info),
        fetchDeleteOrder: (info) => dispatch(info),
        fetchEditPageIndex: (info) => dispatch(info),
    };
};
export default connect(mapStateToProps, mapDispachToProps)(Center);
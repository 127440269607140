import React from 'react';
import '../style/ModalApp.css';
import Loader from './loader.js';
import ModalForm from './ModalFormInputs.js';
import primarypeme from '../style/style';

export default class ModalApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            shown: false,
        }
    }

    close() {
        this.setState({ shown: false });
        this.props.reset();
    }

    render() {
        switch (this.props.name) {
            case 'loading':
                return (
                    <div style={primarypeme.modal_loading}>
                        <Loader/>
                    </div>
                )
            case 'order':
                return (
                    <div style={primarypeme.modal_default_out_view}>
                        <div onClick={() => this.props.func()} style={primarypeme.modal_default_inside_view}>
                        </div>
                        <div style={primarypeme.modal_default}>
                            <ModalForm data={this.props.orderInfo} close_modal={() => this.props.func()} clubs_list={this.props.clubs_list}/>
                        </div>
                    </div>
                )
            default:
                if (this.props.title_name && !this.state.shown) {
                    this.setState({ shown: true });
                }

                let className = 'modal-alert';

                if (this.state.shown) {
                    className += ' shown';
                    setTimeout(() => {
                        this.close();
                    }, 5000);
                }

                return (
                    <div className={className} onClick={() => this.close()}>
                        {this.props.title_name}
                    </div>
                )
        }
    }
}
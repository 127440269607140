import React from 'react';
import '../App.css';
import '../style/Dashboard.css';
import {connect} from "react-redux";
import primaryValues from '../constants/Values.js';
import Center from '../components/Center.js';
import ModalApp from '../components/ModalApp';


class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      tabOneColor: '#181818',
      tabTwoColor: '#131313',
      modalActive: false,
      searchLabel: '',
      searchTlitip: 'You Must Select Mode',
      search: [{name: ''}],
      resultSearch: null
    }
    let params = {
      token: this.props.initialState.user_token,
    }
    this.props.fetchUserToken({type: primaryValues.$GET_TOKEN, params});
  }

  onChangeNewDebounce = (val) => {
    // this.setResultSearch([]);
    // this.props.fetchEditPageIndex({ type: primaryValues.$GET_EDIT_PAGE_INDEX, pageIndex: 1});
    let orders = this.props.initialState.orders;
    let searchArray = [];
    orders.forEach(order => {
      for (const key in order) {
        if (Object.hasOwnProperty.call(order, key)) {
          const element = order[key];
          if (`${element}`.includes(val) && !searchArray.includes(order))
            searchArray = [...searchArray, order]
        }
      }
    });
    this.setResultSearch(searchArray);
    this.setSearchLabel(val)
  }
  // onChangeNewDebounced = debounce(this.onChangeNewDebounce, 1000)
  onChangeNew = (val) => {
    let searchBy = typeof val === 'string' ? val : val.target.value;
    this.setSearchLabel(searchBy)
  }
  openOrderModal = () => {
    this.setState({modalActive: false});
    this.props.initialState.send_success = false;
    this.props.initialState.error_msg = '';
    // const searchLabel = this.state.searchLabel

    /// ******************** REFRESH ALL ORDERS!! *********************
    // setTimeout(() => {
    //     this.props.fetchOrders({
    //         type: primaryValues.$GET_ORDERS,
    //         onChangeNewDebounce: this.onChangeNewDebounce,
    //         searchLabel
    //     });
    // }, 50);
  }

  onSelectedSearch = (val) => {
    let position = !!val.target.textContent;
    if (position) {
      let searchValue = val.target.textContent;
      let searchBy = this.state.searchLabel;
      let orders = this.props.initialState.orders;

      const searchArray = orders.filter(order => {
        return (
            (searchBy === 'ID' && order.id == searchValue)
            || (searchBy === 'Name' && order.name == searchValue)
            || (searchBy === 'Email' && order.email == searchValue)
            || (searchBy === 'Mobile' && order.mobile == searchValue)
        );
      });
      this.setState({resultSearch: searchArray});
    } else
      this.setState({resultSearch: null});
  }
  setResultSearch = (searchArray) => {
    this.setState({resultSearch: searchArray});
  }
  setSearchLabel = (val) => {
    this.setState({searchLabel: val.target ? val.target.textContent : val});
  }
  setSearch = (searchArray) => {
    this.setState({search: searchArray});
  }

  render() {
    if (this.props.initialState.token_success)
      return (
          <div className='App'>
            {this.props.initialState.newImageModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Image..'} /> : null}
            {this.props.initialState.newClubModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Club..'} /> : null}
            {this.props.initialState.newUserModal === true ?
                <ModalApp name={'loading'} title_name={'Add New User..'} /> : null}
            {this.props.initialState.newOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Add New Order..'} /> : null}
            {this.props.initialState.deleteOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Delete Order..'} /> : null}
            {this.props.initialState.updateOrderModal === true ?
                <ModalApp name={'loading'} title_name={'Update Order..'} /> : null}
            {this.props.initialState.sendMessageModal === true ?
                <ModalApp name={'loading'} title_name={'Send Order..'} /> : null}
            {this.props.initialState.sendVaucherToCustomer === true ?
                <ModalApp name={'loading'} title_name={'Send Vaucher To Customer..'} /> : null}
            <ModalApp title_name={this.props.initialState.error_msg} reset={this.openOrderModal} />
            <div className='tab-app'>
              <div className='tab-view'>
                <div className='tab-view-inside'>
                  <Center
                      onChangeNewDebounce={this.onChangeNewDebounce}
                      onChangeNew={this.onChangeNew}
                      setResultSearch={this.setResultSearch}
                      setSearch={this.setSearch}
                      setSearchLabel={this.setSearchLabel}
                      onSelectedSearch={this.onSelectedSearch}
                      resultSearch={this.state.resultSearch}
                      search={this.state.search}
                      searchTlitip={this.state.searchTlitip}
                      searchLabel={this.state.searchLabel}
                  />
                </div>
              </div>
            </div>
          </div>
      );
    else
      return (
          <div className='App'>
            <p style={{fontSize: '14px', color: 'black'}}>Trying to connect the System...</p>
          </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    initialState: state.initialState,
  };
};
const mapDispachToProps = dispatch => {
  return {
    fetchUserToken: (info) => dispatch(info),
    fetchOrders: (info) => dispatch(info),
    // fetchLogout: (info) => dispatch(info),
    // fetchEditPageIndex: (info) => dispatch(info)
  };
};
export default connect(mapStateToProps, mapDispachToProps)(Dashboard);
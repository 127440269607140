import React from 'react';
import '../style/InputApp.css';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default class AutocompleteApp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
        }
    }

    toCapitalCase = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    getSelectedItem() {
        return this.props.options.find((opt) => {
            if (opt.id === this.props.defaultValue) {
                return opt;
            } else if (this.toCapitalCase(opt.name).includes(this.props.defaultValue ? this.toCapitalCase(this.props.defaultValue + '') : null)) {
                return opt;
            }
        });
    }

    render() {
        const capitalize = (s) => {
            if (typeof s !== 'string') return s + '';
            return s.charAt(0).toUpperCase() + s.slice(1)
        }
        return (
            <div className='input-app'>
                <div className='input-app-inside'>
                    {this.props.icon}
                    <Autocomplete
                        options={this.props.options}
                        getOptionLabel={option => capitalize(option.name)}
                        onChange={(e, value) => this.props.onChange(e, this.props.label, value)}
                        style={{width: 170}}
                        // value={this.toCapitalCase(this.props.defaultValue + '')} 
                        defaultValue={this.props.defaultValue ? this.getSelectedItem() : undefined}
                        renderInput={params => (
                            <TextField {...params}
                                       InputLabelProps={{style: {color: this.props.labelColor, fontWeight: 'bold'}}}
                                       label={this.props.label + ''} fullWidth/>
                        )}
                    />
                </div>
            </div>
        );
    }
}
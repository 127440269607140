import React from 'react';
import '../style/InputApp.css';
import TextField from '@material-ui/core/TextField'; 
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
export default class Input extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            id: '',
        }
    }
    render(){
        return(
            <div className='input-app'>
                <div className='input-app-inside'>
                    {this.props.icon} 
                    {!this.props.area ? <TextField   
                        align={this.props.align ? this.props.align : "left"} dir={this.props.dir ? this.props.dir : "ltr"}   
                        style={{margin:0, paddingRight: 10, paddingLeft: 10}} 
                        type={this.props.type}
                        disabled={this.props.disabled ? true : false}
                        InputLabelProps={{style: {color: this.props.labelColor, letterSpacing:1, fontSize:14}}}
                        InputProps={{style: {color: this.props.inputColor}}} 
                        // defaultValue={this.props.defaultValue} 
                        onChange={(e) => this.props.onChange(e,this.props.label)} 
                        value={this.props.value}
                        label={this.props.label} margin="normal" fullWidth />   
                    : <TextareaAutosize 
                        rows={4}
                        align={this.props.align ? this.props.align : "left"} dir={this.props.dir ? this.props.dir : "ltr"}   
                        style={{margin:0, paddingRight: 10, paddingLeft: 10,backgroundColor:'transparent',borderRadius:6}} 
                        type={this.props.type}
                        disabled={this.props.disabled ? true : false}
                        InputLabelProps={{style: {color: this.props.labelColor, letterSpacing:1, fontSize:14}}}
                        InputProps={{style: {color: this.props.inputColor}}} 
                        // defaultValue={this.props.defaultValue} 
                        num
                        onChange={(e) => this.props.onChange(e,this.props.label)} 
                        value={this.props.value}
                        label={this.props.label} margin="normal" fullWidth />   }
                </div>
            </div>
        );
    }
}
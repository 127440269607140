import React from 'react'; 
import '../style/ModalApp.css'; 
import * as Icons from "react-icons/md";
import  * as IconsFa  from "react-icons/fa";
import  * as IconsMi  from "react-icons/go";
import  * as IconsIo  from "react-icons/io"; 
import Input from '../components/InputApp';
import Button from '../components/ButtonApp';
import { connect } from "react-redux"; 
import primaryValues from '../constants/Values';
import AutocompleteApp from './AutocompleteApp';
import ReactTooltip from 'react-tooltip';
class ModalFormInputs extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            id: '', 
            clubs_list: this.props.initialState.clubs,
            users:  this.props.initialState.user_permission === '1' ? this.props.initialState.users : [{name:this.props.initialState.user_email}],
            whatsapp: true,
            viber: true,
            gmail: true,
            sms: false,
            msg: '_',
            error_msg: 0,
            inputMobileOpacity: 0.3,
            inputClubSmsPhone: '',
            whatsappChecked: false,
            viberChecked: false,
            gmailChecked: false,
            smsChecked: false,
            whatsappUrl: null,
            viberUrl: null,
            gmailUrl: null,
            sendBtn: null,
            data:{
                id: this.props.data.id,
                date: this.props.data.date,
                club: this.props.data.club,
                notes: this.props.data.notes,
                people: this.props.data.people,
                name: this.props.data.name,
                mobile: this.props.data.mobile,
                email: this.props.data.email,
                transfers: this.props.data.transfers,
                received_by: this.props.data.received_by,
                type: this.props.data.type,
                confirmed: this.props.data.confirmed,  
                who_put: this.props.data.who_put,
                google: this.props.data.google,
                vip: this.props.data.vip,
                vaucher: this.props.data.vaucher, 
                club_selected: null,
            }
        }
        this.changeState = this.changeState.bind(this);
        this.changeStateCheckBox = this.changeStateCheckBox.bind(this);
        this.checkClubInfo = this.checkClubInfo.bind(this);
        this.sendMessageToClub = this.sendMessageToClub.bind(this);
        this.updateOrderInfo = this.updateOrderInfo.bind(this);
        //*console.log(this.props.initialState.users);
    }
    checkClubInfo = (clubSelected) => { 
        let clubs = this.state.clubs_list; 
        this.setState({gmail: true});
        this.setState({whatsapp: true});
        this.setState({whatsappUrl: null}); 
        this.setState({gmailUrl: null});
        for(let i = 0;i < clubs.length;i++){ 
            if(clubs[i].name === clubSelected){ 
                if(clubs[i].whatsapp !== 'null' && clubs[i].whatsapp !== ' null'){ 
                    if(!clubs[i].whatsapp.indexOf('viber') !== -1){
                        this.setState({whatsapp: false});
                        this.setState({whatsappUrl: clubs[i].whatsapp});
                    } 
                } 
                if(clubs[i].email !== 'null') {  
                    this.setState({gmail: false}); 
                    this.setState({gmailUrl: clubs[i].email});
                }  
                break;
            }
        } 
    }
    changeStateCheckBox = (params) => {
        this.setState({inputMobileOpacity: 0.3});
        if(params.Whatsapp){ 
            this.setState({whatsappChecked: !this.state.whatsappChecked});
            this.setState({gmailChecked: false}); 
            this.setState({smsChecked: false}); 
        }
        if(params.Gmail){
            this.setState({gmailChecked: !this.state.gmailChecked});
            this.setState({smsChecked: false}); 
            this.setState({whatsappChecked: false}); 
        }
        if(params.Sms){
            this.setState({smsChecked: !this.state.smsChecked});
            this.setState({gmailChecked: false}); 
            this.setState({whatsappChecked: false}); 
            this.state.inputMobileOpacity === 1 ? 
            this.setState({inputMobileOpacity: 0.3}) :
            this.setState({inputMobileOpacity: 1});
            //*console.log('sms');
        }
    }
    updateOrderInfo = () => {
        let params = this.state.data;
        if (window.confirm('Update Order ' + this.state.data.id + '?')) {
            this.props.fetchUpdateOrder({ type: primaryValues.$GET_UPDATE_ORDER, params});
            this.props.close_modal();
          } 
    }
    deleteOrder = () =>{
        let params = {order_id: this.state.data.id};
        if (window.confirm('Delete Order ' + this.state.data.id + '?')) {
            this.props.fetchDeleteOrder({ type: primaryValues.$GET_DELETE_ORDER, params});
            this.props.close_modal();
          } 
    }
    sendMessageToClub = () => { 
        this.setState({error_msg: 0});
        this.setState({msg: '_'});
        let valuesFlag = false;
        if(this.state.whatsappChecked === true){
            //*console.log('whatsapp');
            let params = { 
                user_email: this.state.data.email, 
                order_id: this.state.data.id, 
                club: this.state.data.club_selected, 
                date: this.state.data.date,
                people: this.state.data.people,
                name: this.state.data.name, 
                url: this.state.whatsappUrl
            }
            for (const val in params) { 
              if(!params[val])
                valuesFlag = true;
            } 
            if(valuesFlag){
                this.setState({msg: 'Enter club, date,\n full name and people..'});
                this.setState({error_msg: 1});
              //*console.log('valuesFlag ',params);
            }else 
                if (window.confirm('Send Whatsapp Order ' + this.state.data.id + '?')) {
                    this.props.fetchWhatsappMessage({ type: primaryValues.$GET_WHATSAPP_MESSEAGE, params});
                    this.props.close_modal();
                } 
        }
        if(this.state.gmailChecked === true){
            //*console.log('email');
            let params = {
                user_email: this.state.data.email, 
                email: this.state.gmailUrl,  
                order_id: this.state.data.id, 
                club: this.state.data.club_selected, 
                date: this.state.data.date,
                people: this.state.data.people,
                name: this.state.data.name,
            }
            for (const val in params) { 
              if(!params[val])
                valuesFlag = true;
            } 
            if(valuesFlag){
                this.setState({msg: 'Enter club, date,\n full name and people..'});
                this.setState({error_msg: 1});
              //*console.log('valuesFlag ',params);
            }else 
                if (window.confirm('Send Gmail Order ' + this.state.data.id + '?')) {
                    this.props.fetchGmailMessage({ type: primaryValues.$GET_GMAIL_MESSEAGE, params});
                    this.props.close_modal();
                } 
        }
        if(this.state.smsChecked === true){
            //*console.log('sms');
            if(this.state.inputClubSmsPhone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g) && this.state.inputClubSmsPhone.match(/\d/g).length >= 10 && this.state.inputClubSmsPhone.match(/\d/g).length <= 12){
                let params = {
                    user_email: this.state.data.email, 
                    phone: this.state.inputClubSmsPhone, 
                    order_id: this.state.data.id, 
                    club: this.state.data.club, 
                    date: this.state.data.date,
                    people: this.state.data.people,
                    name: this.state.data.name, 
                }
                for (const val in params) { 
                  if(!params[val])
                    valuesFlag = true;
                } 
                if(valuesFlag){
                    this.setState({msg: 'Enter club, date,\n full name and people..'});
                    this.setState({error_msg: 1});
                  //*console.log('valuesFlag ',params);
                }else  
                    if (window.confirm('Send Sms Order ' + this.state.data.id + ' to ' + params.phone + '?')) {
                        this.props.fetchSmsMessage({ type: primaryValues.$GET_SMS_MESSAGE, params});
                        this.props.close_modal();
                    } 
            }else{
                this.setState({msg: 'Check phone number..'});
                this.setState({error_msg: 1});
            }

        }
    }
    changeState = (val,label) => {
        
        switch(label){
            case 'Date': this.state.data.date = val.target.value;
            break;
            case 'Club': this.state.data.club = val.target.value;
            break;
            case 'Notes': this.state.data.notes = val.target.value;
            break;
            case 'People': this.state.data.people = val.target.value;
            break;
            case 'Name': this.state.data.name = val.target.value;
            break; 
            case 'Mobile': this.state.data.mobile = val.target.value;
            break;
            case 'Email': this.state.data.email = val.target.value;
            break;
            case 'Transfers': this.state.data.transfers = val.target.textContent; 
            break; 
            case 'Received By': this.state.data.received_by = val.target.textContent; 
            break; 
            case 'Confirmed': this.state.data.confirmed = val.target.textContent === 'Waiting' ? 'waiting' : val.target.textContent === 'Sent' ? 'padding' : 'approved'; 
            break; 
            case 'Who Put': this.state.data.who_put = val.target.textContent; 
            break; 
            case 'Google': this.state.data.google = val.target.textContent; 
            break; 
            case 'Vip': this.state.data.vip = val.target.textContent; 
            break; 
            case 'Vaucher': this.state.data.vaucher = val.target.textContent; 
            break; 
            case 'Select Club To Send': this.state.data.club_selected = val.target.textContent;
                this.checkClubInfo(val.target.textContent);
            break;
            case 'Club Phone': this.state.inputClubSmsPhone = val.target.value;
            break;
        }
        //*console.log(this.state.data);
    }
    checkColorCon = (val) =>{
        switch(val){
            case 'waiting':
                return 'red';
            case 'padding':
                return '#ff9047';
            case 'approved':
                return 'green';
            default: 
            return '#fcd767';
        }
    }
    checkColor = (val) =>{
        switch(val){
            case 'Yes':
                return 'green';
            case 'No':
                return 'red';
            default: 
            return '#fcd767';
        }
    }
    render(){   
        return( 
            <div className='form' style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <ReactTooltip />
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}} className='form-row'>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',padding:5}}>
                        <Input icon={<IconsFa.FaRegCalendarAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.date + ''} label={'Date'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsFa.FaRegBuilding style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.club + ''} label={'Club'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsMi.GoNote style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.notes + ''} label={'Notes'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsIo.IoIosPeople style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.people + ''} label={'People'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsMi.GoPerson style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.name + ''} label={'Name'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsMi.GoDeviceMobile style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.mobile + ''} label={'Mobile'} type={'text'} onChange={this.changeState} />
                        <Input icon={<IconsMi.GoMail style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={this.state.data.email + ''} label={'Email'} type={'email'} onChange={this.changeState} />
                    </div>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',padding:5}}> 
                        <AutocompleteApp icon={<IconsIo.IoIosSave style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={'#dcdcdc'} options={[{name:'System'},{name:'Office'}]} label={'Received By'} onChange={this.changeState} /> 
                        <AutocompleteApp icon={<IconsFa.FaPencilAlt style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={'#dcdcdc'} options={this.state.users} label={'Who Put'} onChange={this.changeState} />  
                        <AutocompleteApp icon={<IconsFa.FaBookOpen style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={this.checkColorCon(this.state.data.confirmed)} options={[{name:'Waiting'},{name:'Sent'},{name:'Aprovmed'}]} label={'Confirmed'} onChange={this.changeState} /> 
                        <AutocompleteApp icon={<Icons.MdDirectionsTransit style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={this.checkColor(this.state.data.transfers)} options={[{name:'Yes'},{name:'No'}]} label={'Transfers'} onChange={this.changeState} />
                        <AutocompleteApp icon={<IconsFa.FaGoogle style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={this.checkColor(this.state.data.google)} options={[{name:'Yes'},{name:'No'}]} label={'Google'} onChange={this.changeState} /> 
                        <AutocompleteApp icon={<IconsIo.IoIosStar style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={this.checkColor(this.state.data.vip)} options={[{name:'Yes'},{name:'No'}]} label={'Vip'} onChange={this.changeState} />  
                        <AutocompleteApp icon={<Icons.MdSdCard style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={this.checkColor(this.state.data.vaucher)} options={[{name:'Yes'},{name:'No'}]} label={'Vaucher'} onChange={this.changeState} /> 
                    </div>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}> 
                        <AutocompleteApp icon={<Icons.MdReport style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                            labelColor={'#9d1d1d'} options={this.state.clubs_list} label={'Select Club To Send'} onChange={this.changeState} />
                        <div className={'btns-input-order'} >
                            <div style={{ width:'100%',flexDirection:'row',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#101010'}}>
                                <div style={{color:'#9d1d1d',flex:1,flexDirection:'column',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <div style={{color:'#9d1d1d'}} data-tip="Whatsapp">
                                        <IconsFa.FaWhatsapp onClick={() => this.changeStateCheckBox({...this.state.data,Whatsapp: !this.state.data.Whatsapp})} style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>
                                        <input  type="checkbox" disabled={this.state.whatsapp} checked={this.state.whatsappChecked} onChange={(e) => this.changeStateCheckBox({...this.state.data,Whatsapp: e.target.value})} />
                                        
                                    </div>
                                </div>
                                <div style={{color:'#9d1d1d',flex:1,flexDirection:'column',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <div style={{color:'#9d1d1d'}} data-tip="Viber">
                                        <IconsFa.FaViber onClick={() => this.changeStateCheckBox({...this.state.data,Viber: !this.state.data.Viber})} style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>
                                        <input  type="checkbox" disabled={this.state.viber} checked={this.state.viberChecked} onChange={(e) => this.changeStateCheckBox({...this.state.data,Viber: e.target.value})} />
                                       
                                    </div>
                                </div>
                                <div style={{color:'#9d1d1d',flex:1,flexDirection:'column',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <div style={{color:'#9d1d1d'}} data-tip="Email">
                                        <IconsMi.GoMailRead onClick={() => this.changeStateCheckBox({...this.state.data,Gmail: !this.state.data.Gmail})} style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>
                                        <input   type="checkbox" disabled={this.state.gmail} checked={this.state.gmailChecked} onChange={(e) => this.changeStateCheckBox({...this.state.data,Gmail: e.target.value})} />
                                        
                                    </div>
                                </div>
                                <div style={{color:'#9d1d1d',flex:1,flexDirection:'column',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                    <div style={{color:'#9d1d1d'}} data-tip="SMS">
                                        <IconsFa.FaSms onClick={() => this.changeStateCheckBox({...this.state.data,Sms: !this.state.data.Sms})} style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>
                                        <input   type="checkbox" disabled={this.state.sms} checked={this.state.smsChecked} onChange={(e) => this.changeStateCheckBox({...this.state.data,Sms: e.target.value})} />
                                        
                                    </div>
                                </div>
                            </div> 
                            <div style={{opacity: this.state.inputMobileOpacity, pointerEvents: this.state.inputMobileOpacity === 1 ? 'auto' : 'none'}}>
                                <Input icon={<IconsFa.FaSms style={{color:'#2d2d2d',fontSize:24,padding:0,margin:0,marginRight:5 }}/>} 
                                    inputColor={'#e9e9e9'} labelColor={'#dcdcdc'} value={'+' + this.state.inputClubSmsPhone} label={'Club Phone'} type={'text'} onChange={this.changeState} />
                            </div>
                            <p style={{opacity: this.state.error_msg,fontSize:14,color:'#9d1d1d',margin:0}}>{this.state.msg}</p>
                            <Button onClick={this.sendMessageToClub} name={'Send Order To Club'}/>
                            <Button onClick={this.updateOrderInfo} name={'Save Order'}/>
                            <Button onClick={this.deleteOrder} name={'Delete Order'}/>
                        </div>
                    </div> 
                </div>
            </div> 
        )
    }
}

const mapStateToProps = state => {
    return {
      initialState: state.initialState,
    };
  };
  const mapDispachToProps = dispatch => {
    return {
      fetchWhatsappMessage: (info) => dispatch(info),
      fetchGmailMessage: (info) => dispatch(info),
      fetchSmsMessage: (info) => dispatch(info),
      fetchUpdateOrder: (info) => dispatch(info),
      fetchDeleteOrder: (info) => dispatch(info)
    };
  };
  export default connect(mapStateToProps,mapDispachToProps)(ModalFormInputs);